import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { firstValueFrom } from 'rxjs';
import { environment } from 'src/environment/environment';

@Injectable({
  providedIn: 'root',
})
export class SearchService {
  constructor(private httpClient: HttpClient) {}

  async search(
    siteName: string,
    language: string,
    searchTerm: string,
  ): Promise<SearchResult[]> {
    return await firstValueFrom(
      this.httpClient.get<SearchResult[]>(
        `${environment.api}${environment.middleware}TextSearch?searchText=${searchTerm}&language=${language}&appName=${siteName}`,
      ),
    );
  }
}
