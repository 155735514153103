import { HttpErrorResponse, HttpInterceptorFn, HttpRequest } from '@angular/common/http';
import { inject } from '@angular/core';
import { MessageService } from 'primeng/api';
import { catchError, throwError } from 'rxjs';
import { TranslateService } from '../services/translate.service';

const getCustomErrorMessage = (error: HttpErrorResponse, translateService: TranslateService) => {
  if (typeof error.error === 'string') {
    return error.error;
  } else if (error.error?.message) {
    return error.error.message;
  }

  if (error.status === 401) {
    return translateService.translate('401ErrorText', 'You are not authorized, please login and try again');
  }
  if (error.status === 500) {
    return translateService.translate('500ErrorText', 'An error occurred, please try again later');
  }

  return error.message;
};

const ignoreError = (req: HttpRequest<unknown>, error: HttpErrorResponse) => {
  if (req.url.includes('/TextSearch') && error.status === 404) return true;
  return false;
};

export const httpErrorNotificationInterceptor: HttpInterceptorFn = (req, next) => {
  const messageService = inject(MessageService);
  const translateService = inject(TranslateService);
  return next(req).pipe(
    catchError((error: HttpErrorResponse) => {
      if (!ignoreError(req, error)) {
        const message = getCustomErrorMessage(error, translateService);
        messageService.add({ severity: 'error', summary: translateService.translate('Error'), detail: message });
      }
      return throwError(() => error);
    }),
  );
};
