import { CommonModule } from '@angular/common';
import { Component, Input, OnChanges, Signal, SimpleChanges } from '@angular/core';
import { RouterLink, RouterLinkActive } from '@angular/router';
import { AppStore } from 'src/app/store/app.store';
import { StickyHeaderDirective } from '../../directives/sticky-header.directive';
import { BannerComponent } from '../banner/banner.component';
import { EmbedHtmlComponent } from '../embed-html/embed-html.component';
import { HeaderNavComponent } from '../header-nav/header-nav.component';
import { ImagesComponent } from '../images/images.component';
import { LanguageComponent } from '../language/language.component';
import { MenuComponent } from '../menu/menu.component';
import { QuickSearchComponent } from '../quick-search/quick-search.component';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss'],
  standalone: true,
  imports: [
    CommonModule,
    ImagesComponent,
    LanguageComponent,
    MenuComponent,
    BannerComponent,
    HeaderNavComponent,
    StickyHeaderDirective,
    RouterLink,
    RouterLinkActive,
    EmbedHtmlComponent,
    QuickSearchComponent,
  ],
})
export class HeaderComponent implements OnChanges {
  @Input() headerContent!: Header;
  @Input() menu!: Menu;
  type: HeaderMenuType = 'Hamburger';
  showSecondaryNav = true;
  $siteHome: Signal<string>;

  menuList: Menu = this.menu;

  $showLogonButton = this.appStore.$showLogonButton;

  constructor(private appStore: AppStore) {
    this.$siteHome = this.appStore.$siteHome;
  }

  ngOnChanges(changes: SimpleChanges): void {
    if ('menu' in changes || 'headerContent' in changes) {
      this.menuList = this.menu || this.headerContent.Menu;
      this.type = this.headerContent?.MenuType || 'Hamburger';
      this.showSecondaryNav = this.type === 'Dropdown';
    }
  }

  getSiteHome() {
    return this.$siteHome();
  }

  public get hasBanner() {
    return this.headerContent?.Banner?.length > 0;
  }
}
