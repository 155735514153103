import { Component } from '@angular/core';
import { DialogService } from 'primeng/dynamicdialog';
import { IconFieldModule } from 'primeng/iconfield';
import { InputIconModule } from 'primeng/inputicon';
import { InputTextModule } from 'primeng/inputtext';
import { TranslateSignalPipe } from '../../pipes/translate-signal.pipe';
import { QuickSearchModalComponent } from './quick-search-modal/quick-search-modal.component';

@Component({
  selector: 'app-quick-search',
  standalone: true,
  imports: [
    IconFieldModule,
    InputIconModule,
    InputTextModule,
    TranslateSignalPipe,
  ],
  templateUrl: './quick-search.component.html',
  styleUrl: './quick-search.component.scss',
  providers: [DialogService],
})
export class QuickSearchComponent {
  constructor(private readonly dialogService: DialogService) {

  }
  onFocus() {
    this.dialogService.open(QuickSearchModalComponent, {
      header: 'Quick Search',
      width: '70%',
      style: { 'max-width': '800px' },
      baseZIndex: 10000,
      closable: true,
    });
  }
}
