<div class="quick-search-modal">
    <p-iconField iconPosition="left">
        <p-inputIcon styleClass="fa fa-search" />
        <input
            type="text"
            class="tw-w-full quick-search-input"
            pInputText
            [placeholder]="('Search' | translateSignal)()"
            #searchInput
            (input)="searchValue$.next(searchInput.value)"
        />
    </p-iconField>
    <hr />
    @if ($loading()) {
        <div class="tw-text-center">Searching...</div>
    }
    <div>
        @for (result of $searchResults(); track $index) {
            @if (result.pageTitle) {
                <div class="quick-search-result tw-my-4">
                    <div>
                        <a [routerLink]="result.pageSlug" (click)="onClickLink()">
                            {{ result.pageTitle }}</a
                        >
                    </div>
                    <small>{{ result.pageDescription }}</small>
                </div>
            }
        }
        @if (!$loading() && $searchResults() !== null && $searchResults()!.length <= 0) {
            <div class="tw-text-center tw-p-4">No results found</div>
        }
    </div>
</div>
