import { AsyncPipe } from '@angular/common';
import { Component, signal } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { DialogModule } from 'primeng/dialog';
import { DialogService, DynamicDialogRef } from 'primeng/dynamicdialog';
import { IconFieldModule } from 'primeng/iconfield';
import { InputIconModule } from 'primeng/inputicon';
import { InputTextModule } from 'primeng/inputtext';
import { ProgressSpinnerModule } from 'primeng/progressspinner';
import { BehaviorSubject, debounceTime } from 'rxjs';
import { SearchService } from 'src/app/shared/services/search.service';
import { AppStore } from 'src/app/store/app.store';
import { TranslateSignalPipe } from '../../../pipes/translate-signal.pipe';

@Component({
  selector: 'app-quick-search-modal',
  standalone: true,
  imports: [
    DialogModule,
    IconFieldModule,
    InputTextModule,
    InputIconModule,
    ProgressSpinnerModule,
    TranslateSignalPipe,
    FormsModule,
    RouterModule,
    AsyncPipe,
  ],
  templateUrl: './quick-search-modal.component.html',
  styleUrl: './quick-search-modal.component.scss',
  providers: [DialogService],
})
export class QuickSearchModalComponent {
  searchValue$ = new BehaviorSubject('');
  $loading = signal(false);
  $searchResults = signal<SearchResult[] | null>(null);

  constructor(
    private dialogService: DialogService,
    private appStore: AppStore,
    private searchService: SearchService,
    private dialogRef: DynamicDialogRef<QuickSearchModalComponent>,
  ) {
    this.searchValue$.pipe(debounceTime(1000)).subscribe({
      next: (value) => {
        if (value) this.search(value);
      },
    });
  }
  // onKeyDown(event: KeyboardEvent) {
  //   if (event.key === 'Enter') {
  //     this.search(this.searchValue.value);
  //   }
  // }

  async search(searchValue: string) {
    const appName = this.appStore.$appName();
    const language = this.appStore.$language();
    this.$loading.set(true);
    try {
      const res = await this.searchService.search(
        appName,
        language.code,
        searchValue,
      );

      this.$searchResults.set(res);
    } catch (err) {
      console.error(err);
      this.$searchResults.set([]);
    }
    this.$loading.set(false);
  }

  onClickLink() {
    this.dialogRef.close();
  }
}
