<div class="DTX_QUICK_SEARCH tw-mx-4">
    <p-iconField iconPosition="left">
        <p-inputIcon styleClass="fa fa-search" />
        <input
            type="text"
            pInputText
            [placeholder]="('Search' | translateSignal)()"
            (focus)="onFocus()"
        />
    </p-iconField>
</div>
