import { Component, OnDestroy } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { cloneDeep } from 'lodash-es';
import { MenuItem } from 'primeng/api';
import { ButtonModule } from 'primeng/button';
import { DropdownModule } from 'primeng/dropdown';
import { MenuModule } from 'primeng/menu';
import { Subject } from 'rxjs';
import { RouteQuery, RouteService } from 'src/app/store';
import { AppStore } from 'src/app/store/app.store';
import { defaultLanguage, languages } from 'src/constants/languages.constants';
import { Language } from 'src/types/language';

@Component({
  selector: 'app-language',
  templateUrl: './language.component.html',
  styleUrls: ['./language.component.scss'],
  providers: [RouteService, RouteQuery],
  standalone: true,
  imports: [FormsModule, DropdownModule, ButtonModule, MenuModule],
})
export class LanguageComponent implements OnDestroy {
  languages: Language[] = languages;
  selectedLanguageCode = '';
  protected unsubscribe = new Subject<void>();
  protected readonly defaultLanguage = defaultLanguage;

  items: MenuItem[] = [];

  constructor(
    private routeQuery: RouteQuery,
    private routeService: RouteService,
    private activatedRoute: ActivatedRoute,
    private router: Router,
    private appStore: AppStore,
  ) {
    this.selectedLanguageCode = this.appStore.$language().code;

    this.items = this.languages.map((language) => ({
      label: language.name,
      command: () => this.onChange(language.code),
    }));
  }

  onChange(value: string) {
    const language =
      languages.find((language) => language.code === value) ?? defaultLanguage;
    this.selectedLanguageCode = language.code;
    const currentRoute = cloneDeep(this.routeQuery.get());
    this.routeService.set({
      param: currentRoute.param,
      language,
      preview: currentRoute.preview,
    });
    this.updateQueryParam('lang', language.code);
  }

  updateQueryParam(paramName: string, value: any) {
    // Get current query parameters
    const queryParams = this.activatedRoute.snapshot.queryParams;

    // If the parameter isn't already set
    if (!queryParams[paramName] || queryParams[paramName] !== value) {
      // Clone the current parameters and add/update the parameter you want
      const newQueryParams = { ...queryParams, [paramName]: value };

      // Navigate with updated parameters without navigating away from the current route
      this.router.navigate([], {
        relativeTo: this.activatedRoute,
        queryParams: newQueryParams,
        queryParamsHandling: 'merge',
      });
    }
  }

  ngOnDestroy() {
    this.unsubscribe.next();
    this.unsubscribe.complete();
  }
}
